<template>
  <v-container grid-list-xl>
    <app-card heading="Customer invoices">
      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm3>
          <v-select
            v-if="brandList.length"
            :items="brandList"
            v-model="filters.brands"
            item-text="name"
            item-value="id"
            label="Brand"
            hide-details
            required
          />
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-menu
            ref="invoicedAtFrom"
            v-model="invoicedAtFromFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.invoicedAtFrom"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.invoicedAtFrom"
                v-bind="attrs"
                :disabled="!Boolean(!monthFilter)"
                label="Invoiced at from"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filters.invoicedAtFrom"
              :first-day-of-week="1"
              no-title
              scrollable
              range
              @change="
                () => {
                  $refs.invoicedAtFrom.save(filters.invoicedAtFrom)
                  invoicedAtFromFilterMenu = false
                }
              "
            />
          </v-menu>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-menu
            ref="invoicedAtTo"
            v-model="invoicedAtToFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="filters.invoicedAtTo"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.invoicedAtTo"
                v-bind="attrs"
                :disabled="!Boolean(!monthFilter)"
                label="Invoiced at to"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filters.invoicedAtTo"
              :first-day-of-week="1"
              no-title
              scrollable
              range
              @change="
                () => {
                  $refs.invoicedAtTo.save(filters.invoicedAtTo)
                  invoicedAtToFilterMenu = false
                }
              "
            />
          </v-menu>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-menu
            ref="month"
            v-model="monthFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="monthFilter"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="monthFilter"
                v-bind="attrs"
                label="Month"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="monthFilter"
              no-title
              scrollable
              range
              type="month"
              @change="
                () => {
                  $refs.month.save(monthFilter)
                  monthFilterMenu = false
                }
              "
            />
          </v-menu>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-select
            :items="typeList"
            v-model="filters.type"
            item-text="text"
            item-value="value"
            label="Type"
            hide-details
            required
          />
        </v-flex>
        <v-flex
          xs6
          sm4
          md3>
          <group-switch
            :value="filters.draft"
            :option-values="draftOptionValues"
            label="Draft"
            @onChange="onDraftChange" />
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm4
          md3>
          <v-text-field
            v-model="filters.search"
            label="Search"
            outline
          />
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="disabledDeleteinvoices"
            class="ma-0"
            dark
            color="red"
            @click="deleteCombinedInvoices">
            Delete concept invoice
          </v-btn>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="!selectedInvoices || !selectedInvoices.length"
            class="ma-0"
            dark
            color="primary"
            @click="sendCombinedInvoices">
            Send selected invoices
          </v-btn>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="disabledDeleteinvoices"
            class="ma-0"
            dark
            color="primary"
            @click="reopenCombinedInvoices">
            Reopen selected invoices
          </v-btn>
        </v-flex>
        <v-flex
          xs6
          sm3>
          <v-btn
            :disabled="disabledCreditInvoices"
            class="ma-0"
            dark
            color="primary"
            @click="creditCombinedInvoices">
            Credit selected invoices
          </v-btn>
        </v-flex>
      </v-layout>
      <invoice-table
        :invoices="combinedInvoices"
        :loading="loading"
        :filters="filters"
        :change-table-sort="changeTableSort"
        :invoice-edit="invoiceEdit"
        selectable-many
      />
    </app-card>
  </v-container>
</template>

<script>
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import moment from 'moment-timezone'
import GroupSwitch from 'Components/GroupSwitch'
import InvoicePdf from 'Components/Invoice/InvoicePdf'
import InvoiceTable from 'Components/Invoice/InvoiceTable'
import CombinedInvoices from '../../../helpers/combined-invoices'

export default {
  components: { GroupSwitch, InvoicePdf, InvoiceTable },
  mixins: [Request, Brands, CombinedInvoices],
  data () {
    return {
      moment,
      brandList: [],
      combinedInvoices: [],
      loading: false,
      sorting: [
        {
          title: 'Customer Id',
          name: 'passengerId',
          dir: 'asc'
        }
      ],
      filters: {
        brands: null,
        invoicedAtFrom: moment(Date.now()).format('YYYY-MM-DD'),
        invoicedAtTo: moment(Date.now()).format('YYYY-MM-DD'),
        draft: null,
        type: null,
        search: ''
      },
      monthFilter: null,
      invoicedAtFromFilterMenu: false,
      invoicedAtToFilterMenu: false,
      monthFilterMenu: false,
      csvData: [],
      draftOptionValues: {
        on: { text: 'ON', value: true },
        middle: { text: 'ALL', value: null },
        off: { text: 'OFF', value: false }
      },
      typeList: [
        { text: 'All', value: null },
        { text: 'Combined', value: 'combined' },
        { text: 'Credit', value: 'credit' }
      ]
    }
  },
  computed: {
    selectedInvoices () {
      return this.combinedInvoices.filter((item) => item.isSelected)
    },
    disabledDeleteinvoices () {
      const isDisabled = this.selectedInvoices.some((item) => item.sentAt)
      return isDisabled || !this.selectedInvoices || !this.selectedInvoices.length
    },
    disabledCreditInvoices () {
      const isDisabled = this.selectedInvoices.some((item) => item.type === 'credit')
      return isDisabled || !this.selectedInvoices || !this.selectedInvoices.length
    }
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.updateParams()
      }
    },
    monthFilter: {
      deep: true,
      handler (newValue, oldValue) {
        this.changeMonthFilter()
      }
    }
  },
  async beforeMount () {
    try {
      const [brandList] = await Promise.all([this.fetchBrands()])

      this.brandList = brandList

      const updatedProps = {}
      const _filters = { ...this.filters }

      if (this.brandList && this.brandList.length) {
        _filters.brands = this.brandList[2].id
      }

      if (this.$route.query.sort) {
        _filters.sort = this.$route.query.sort
        updatedProps.sort = this.$route.query.sort
      } else {
        _filters.sort = this.sorting[0].name
        updatedProps.sort = this.sorting[0].name
      }

      if (this.$route.query.sortDir) {
        _filters.sortDir = this.$route.query.sortDir
        updatedProps.sortDir = this.$route.query.sortDir
      } else {
        _filters.sortDir = this.sorting[0].dir
        updatedProps.sortDir = this.sorting[0].dir
      }

      if (this.$route.query.search) {
        _filters.search = this.$route.query.search
        updatedProps.search = this.$route.query.search
      }

      if (this.$route.query.brands) {
        _filters.brands = parseInt(this.$route.query.brands)
        updatedProps.brands = parseInt(this.$route.query.brands)
      }

      if (this.$route.query.invoicedAtFrom) {
        _filters.invoicedAtFrom = this.$route.query.invoicedAtFrom
        updatedProps.invoicedAtFrom = moment.utc(this.$route.query.invoicedAtFrom).format()
      }

      if (this.$route.query.invoicedAtTo) {
        _filters.invoicedAtTo = this.$route.query.invoicedAtTo
        updatedProps.invoicedAtTo = moment.utc(this.$route.query.invoicedAtTo).format()
      }

      if (this.$route.query.type) {
        _filters.type = this.$route.query.type
        updatedProps.type = this.$route.query.type
      }

      if (this.$route.query.month) {
        this.monthFilter = this.$route.query.month
      }

      if (
        !this.$route.query.month &&
        !this.$route.query.invoicedAtFrom &&
        this.$route.query.invoicedAtTo
      ) {
        this.monthFilter = moment(Date.now())
          .add('month', -1)
          .set('date', 1)
          .set('hours', 24)
          .format('YYYY-MM')
      }

      this.filters = _filters

      this.getCombinedInvoices(updatedProps)
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    invoiceEdit (invoice) {
      const body = { data: { invoicedAt: moment(invoice.invoicedAt).format('YYYY-MM-DDTHH:mm'), rides: invoice.rides.map(item => item.id) } }
      this.request('PATCH', `/combined-invoices/${invoice.id}`, body, ({ data }) => {
        invoice.invoiceEditDialog = false

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'invoice has been updated'
        })
        this.updateParams()
      })
    },
    async sendCombinedInvoices () {
      this.loading = true
      await this.sendCombinedInvoicesBulk(this.selectedInvoices)

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'Invoices sent successfully'
      })

      this.loading = false
      this.updateParams()
    },
    async reopenCombinedInvoices () {
      this.loading = true
      this.reopenCombinedInvoicesBulk(this.selectedInvoices)

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'Invoices reopen successfully'
      })

      this.loading = false
      this.updateParams()
    },
    async creditCombinedInvoices () {
      this.loading = true
      await this.creditCombinedInvoicesBulk(this.selectedInvoices)

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'Invoices credit successfully'
      })

      this.loading = false
      this.updateParams()
    },
    async deleteCombinedInvoices () {
      this.loading = true
      await this.deleteCombinedInvoicesBulk(this.selectedInvoices)

      this.$notify({
        group: 'topRight',
        type: 'success',
        text: 'invoices successfully deleted'
      })

      this.loading = false
      this.updateParams()
    },
    changeMonthFilter () {
      if (this.monthFilter) {
        this.filters = {
          ...this.filters,
          invoicedAtFrom: this.moment(this.monthFilter)
            .set('date', 1)
            .format('YYYY-MM-DD'),
          invoicedAtTo: this.moment(this.monthFilter)
            .add('month', 1)
            .set('date', 1)
            .format('YYYY-MM-DD')
        }
      } else {
        this.filters = {
          ...this.filters,
          invoicedAtFrom: null,
          invoicedAtTo: null
        }
      }
    },
    async getCombinedInvoices (params = {}) {
      this.loading = true
      let body = { params: { ...params, pageSize: 200 } }

      await this.request('GET', `/combined-invoices`, body, ({ data }) => {
        this.loading = false
        this.combinedInvoices = data.data.map((item) => ({ ...item, invoiceEditDialog: false, invoicedAt: this.moment.utc(item.invoicedAt).format('DD.MM.yyyy HH:mm') }))
      })
    },
    onChangeBrands (brandsId) {
      this.filters.brands = brandsId
    },
    onDraftChange (value) {
      this.filters.draft = value
    },
    updateParams () {
      let params = {}

      let updatedRouteQuery = { ...this.$route.query }

      if (this.filters.sort) {
        params.sort = this.filters.sort
        updatedRouteQuery = {
          ...updatedRouteQuery,
          sort: this.filters.sort
        }
      }

      if (this.filters.sortDir) {
        params.sortDir = this.filters.sortDir
        updatedRouteQuery = {
          ...updatedRouteQuery,
          sortDir: this.filters.sortDir
        }
      }

      if (this.filters.search) {
        params.search = this.filters.search
        updatedRouteQuery = {
          ...updatedRouteQuery,
          search: this.filters.search
        }
      } else {
        delete updatedRouteQuery.search
        delete params.search
      }

      if (this.filters.brands) {
        updatedRouteQuery = { ...updatedRouteQuery, brands: this.filters.brands }
        params.brands = this.filters.brands
      } else {
        delete updatedRouteQuery.brands
        delete params.brands
      }

      if (this.filters.invoicedAtFrom) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          invoicedAtFrom: this.filters.invoicedAtFrom
        }
        params.invoicedAtFrom = moment.utc(this.filters.invoicedAtFrom).format()
      } else {
        delete updatedRouteQuery.invoicedAtFrom
        delete params.invoicedAtFrom
      }

      if (this.filters.invoicedAtTo) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          invoicedAtTo: this.filters.invoicedAtTo
        }
        params.invoicedAtTo = moment.utc(this.filters.invoicedAtTo).set('hours', 24).format()
      } else {
        delete updatedRouteQuery.invoicedAtTo
        delete params.invoicedAtTo
      }

      if (this.monthFilter) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          month: this.monthFilter
        }
      } else {
        delete updatedRouteQuery.month
      }

      if (this.filters.passenger) {
        const passengerId = this.filters.passenger.id || this.filters.passenger
        params.passenger = passengerId
        updatedRouteQuery = { ...updatedRouteQuery, passenger: passengerId }
      } else {
        delete updatedRouteQuery.passenger
        delete params.passenger
      }

      if (this.filters.type) {
        params.type = this.filters.type
        updatedRouteQuery = { ...updatedRouteQuery, type: this.filters.type }
      } else {
        delete updatedRouteQuery.type
        delete params.type
      }

      if (this.filters.draft !== null && this.filters.draft !== undefined) {
        updatedRouteQuery = {
          ...updatedRouteQuery,
          draft: this.filters.draft
        }
        params.draft = this.filters.draft
      } else {
        delete updatedRouteQuery.draft
        delete params.draft
      }

      this.$router.replace({ query: updatedRouteQuery }).catch((err) => err)

      this.getCombinedInvoices(params)
    },
    changeTableSort (headerColumn) {
      if (!headerColumn.customSortable) {
        return null
      }

      this.filters = {
        ...this.filters,
        sort: headerColumn.value,
        sortDir: this.filters.sortDir === 'desc' ? 'asc' : 'desc'
      }
    }
  }
}
</script>
